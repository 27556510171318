(function(angular) {
  'use strict';

  angular.module('linagora.esn.admin')

    .component('adminTechnicalUsersSubheader', {
      template: require('./admin-technical-users-subheader.pug')
    });

})(angular);
